import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";
import { bold18, bold20, bold24, bold32, bold36, bold48, semibold16 } from "./typography";

const StyledWestfiledCopyWrapper = styled.div`
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    padding-bottom: 1rem;
    
    right: 0;
    z-index: 10;
    line-height: 2;
    padding-top: 30rem;
    @media screen and (min-width: ${breakpoints.l}px) {
        padding-top: 6rem;
        padding-left: 4rem;
    }
   
   p {
       color: #fff;
       font-weight: 700;
       ${bold20}
       text-align: left;
       float: left; 
       @media screen and (min-width: ${breakpoints.md}px) {
        ${props => props.long ? 'width: 60%' : 'width: 100%'}
        ${bold18}
       }
       @media screen and (min-width: ${breakpoints.l}px) {
        ${bold36}
        font-weight: 900;
        line-height: 1.6;
        width: 100%;
       }

       &:nth-child(2) {
           text-align: center;
           width: 100%;
           @media screen and (min-width: ${breakpoints.md}px) {
           text-align: right;
            float: right; 
            padding-right: 4rem;
            font-style: normal;
            position: absolute;
            bottom: 6rem;
            right: 0;
           }
       }

        &.short {
           bottom: 8rem;
           width: 100%;
           position: absolute;
           text-align: center;
           left: 4rem;
           @media screen and (min-width: ${breakpoints.sm}px) {
            text-align: left;
            bottom: 6rem;
            position: relative;
           }    
            
        }
   }
`


export {
    StyledWestfiledCopyWrapper
}