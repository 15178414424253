
import React from "react"
import { StyledWestfiledCopyWrapper } from "../styledComponents/westfiledCopy"
const WestfieldCopy = (data) => {
    return (
        <StyledWestfiledCopyWrapper>
        <p className="short">&#8226; Wellbeing Education<br /> &#8226; Corporate Wellness<br />&#8226; Workshops, Healing &amp; Coaching<br />&#8226; Mindfulness Audio Programs</p>
        <p>karina@karinagrant.co.uk<br />
        www.karinagrant.co.uk</p>

        </StyledWestfiledCopyWrapper>
    )
}

export default WestfieldCopy
