import styled from "styled-components"
import { baseGridStyles } from "./base"
import breakpoints from "./breakpoints"
import { bold28, bold36, bold52, bold72 } from "./typography"

const ImageFullBackground = styled.div`
  background: url(https://karinagrant.s3.eu-west-1.amazonaws.com/images/serene-background-1.jpg) no-repeat center center fixed; 
  // background: url(https://karinagrant.s3.eu-west-1.amazonaws.com/images/serene-background-2.jpg) no-repeat center center fixed; 
  // background: url(https://karinagrant.s3.eu-west-1.amazonaws.com/images/serene-background-3.jpg) no-repeat center center fixed; 
  // background: url(https://karinagrant.s3.eu-west-1.amazonaws.com/images/serene-background-4.jpg) no-repeat center center fixed; 
  // background: url(https://karinagrant.s3.eu-west-1.amazonaws.com/images/serene-background-5.jpg) no-repeat center center fixed; 
   background: url(https://karinagrant.s3.eu-west-1.amazonaws.com/images/serene-background-6-qr-code-min.jpg) no-repeat center center fixed; 
  //background: url(https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/wp-content/uploads/2019/09/bloom-blooming-blossom-462118-1320x880.jpg) no-repeat center center fixed; 
    
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh; 
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  .logo-wrapper {
    position: absolute;
    top: 4rem;
    left: 0;
    z-index: 10;
  }
  svg {
    background: #fff;
    padding: 1rem 3.8rem 1rem 4rem;
    border-radius: 0 25px 25px 0;
    height: 5.8rem;
    width: 30rem;

    @media screen and (min-width: ${breakpoints.l}px) {
      zoom: 1.4;
      height: 6rem;
      width: 32rem;
    }
  }
  p.website {
    font-weight: 700;
    font-size: 4.8rem;
    padding-left: 6.8rem;
    color: #fff;
    margin-top: 1.6rem;
    position: absolute;
    text-shadow: 1px 1px 2px #000000;
    // text-transform: uppercase;
  }

  .program-name {
    text-align: center;
    padding: 6rem 4rem;
    font-weight: 900;
    
    

      p {
        ${bold28};
        line-height: 1.7;
        color: var(--light-purple-dark);
        text-shadow: 3px 4px 11px #ffffff;

        @media screen and (min-width: ${breakpoints.l}px) {
          letter-spacing: 0.5rem;
          ${bold72};
        }
        span {
          display: block;
          ${bold36};
        }
        &.intro {
          ${bold52};
        }
      }
  }
`
const GradientWrapper = styled.div`
background: rgb(0,0,0);
background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7469581582633054) 25%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.7525603991596639) 75%, rgba(255,255,255,0) 100%, rgba(0,0,0,1) 1000%);
    
`
export {
  ImageFullBackground,
  GradientWrapper
}